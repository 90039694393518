interface Sponsors {
    id: string,
    name: string,
    link: string
}

export const EnterpriseSponsors: Sponsors[] = [
    /* {
        id: "bc",
        name: "Bétula Cars",
        link: "https://www.betulacars.es/"
    }, */
    {
        id: "cenor",
        name: "Cenor Electrodomésticos",
        link: "https://www.cenor.es/"
    },
    /* {
        id: "dc",
        name: "Deputación da Coruña",
        link: "https://www.dacoruna.gal/portada/"
    }, */
    {
        id: "eci",
        name: "El Corte Inglés",
        link: "https://www.elcorteingles.es/"
    },
    {
        id: "hyupersa",
        name: "Hyupersa Santiago",
        link: "https://www.hyundai.es/concesionarios/hyupersasantiago"
    },
];

export const BusinessSponsors: Sponsors[] = [
    {
        id: "dh",
        name: "dinahosting",
        link: "https://dinahosting.com/"
    },
    /* {
        id: "fyn",
        name: "Flor y Nata",
        link: "https://florynatasantiago.com/"
    }, */
    {
        id: "mfs",
        name: "Multiusos Fontes do Sar",
        link: "https://www.multiusos.net/"
    },
    /* {
        id: "myka",
        name: "MYKA",
        link: "https://www.mykadeco.com/"
    },
    {
        id: "oca",
        name: "OCA Hotels",
        link: "https://www.ocahotels.com/"
    }, */
    {
        id: "siso",
        name: "SISODENTAL",
        link: "https://goo.gl/maps/rtJLqDm28QQXKHug9"
    },
    /* {
        id: "tt",
        name: "TeeTravel",
        link: "https://www.tee-travel.com/"
    },
    {
        id: "wovo",
        name: "Wovo",
        link: "https://www.wovorevolution.com/"
    }, */
];

export const BasicSponsors: Sponsors[] = [
    {
        id: "aliviflex",
        name: "Aliviflex",
        link: "https://aliviflex.com/"
    },
    {
        id: "amura",
        name: "Amura Sport",
        link: "https://www.instagram.com/amurasport/"
    },
    /* {
        id: "armonia",
        name: "Armonia",
        link: "https://armoniabio.com/"
    },
    {
        id: "cd",
        name: "Centro Deportivo",
        link: "http://centrodeportivo.es/"
    }, */
    {
        id: "clun",
        name: "Cooperativas Lácteas Unidas",
        link: "https://clun.es/",
    },
    {
        id: "gadis",
        name: "Gadis",
        link: "https://www.gadis.es/"
    },
    {
        id: "caminante",
        name: "El Caminante",
        link: "https://goo.gl/maps/KvaUbGYepU9mo8UcA/"
    },
    {
        id: "galeon",
        name: "Restaurante Galeón Toural",
        link: "https://www.galeontoural.com/"
    },
    {
        id: "lc",
        name: "LauCreaciones",
        link: "https://laucreaciones.com/"
    },
    {
        id: "parking",
        name: "Parking Catedral",
        link: "https://goo.gl/maps/GNiFdPDjDLkegKKT6/"
    },
    /* {
        id: "hoko",
        name: "HOKO",
        link: "https://www.hokosport.com/"
    } */
];

export const StarterSponsors: Sponsors[] = [
    {
        id: "anova",
        name: "Anova",
        link: "https://www.anova.es/"
    },
    {
        id: "fm",
        name: "Centro de Fisioterapia Mera",
        link: "https://www.facebook.com/merafisioterapia/"
    },
    {
        id: "df",
        name: "Desguaces Ferreiro",
        link: "https://maps.app.goo.gl/TqMqhDCt51v6dTDu7/"
    },
    {
        id: "pn",
        name: "Pavimentos Noia",
        link: "https://www.pavimentosnoia.com/"
    },
    /* {
      id: "frutas",
      name: "Frutas Barreiro",
      link: "https://goo.gl/maps/HyfpQNxbHoG6qyiz8",
    },
    {
        id: "bulideira",
        name: "Bulideira",
        link: "https://www.bulideira.es/"
    },
    {
        id: "xirandaina",
        name: "Xirandaina",
        link: "https://www.facebook.com/asociacion.xirandaina/"
    },
    {
        id: "rqr",
        name: "Charanga RqR",
        link: "https://www.facebook.com/profile.php?id=100054793129167"
    }, */
];


export const Organizers: Sponsors[] = [
    {
        id: "deportes",
        name: "Departamento de Deportes",
        link: "http://santiagodecompostela.gal/"
    },
    {
        id: "concello",
        name: "Concello de Santiago",
        link: "http://santiagodecompostela.gal/"
    },
    {
        id: "fga",
        name: "Federación Galega de Atletismo",
        link: "https://atletismo.gal/"
    },
    /* {
        id: "rfea",
        name: "Real Federación Española de Atletismo",
        link: "https://www.rfea.es/"
    }, */
    {
        id: "dc",
        name: "Deputación da Coruña",
        link: "https://www.dacoruna.gal/portada/"
    },
    {
        id: "cafs",
        name: "Club Atletismo Fontes do Sar",
        link: "https://www.atletismofontesdosar.es/"
    },
];

export const Partners: Sponsors[] = [
    {
        id: "aspanaes",
        name: "Aspanaes",
        link: "https://aspanaes.org/"
    },
    {
        id: "enki",
        name: "Proyecto ENKI",
        link: "https://www.enkiproyecto.com/"
    },
    {
        id: "pl",
        name: "Policía Local - Santiago de Compostela",
        link: "http://santiagodecompostela.gal/casa_concello/servizo.php?txt=ser_policia_municipal&lg=cas&c=36"
    },
    {
        id: "pc",
        name: "Protección Civil - Santiago de Compostela",
        link: "https://proteccioncivil.sdc.gal/"
    },
    /* {
        id: "cv",
        name: "Cruz Vermella",
        link: "https://www.cruzvermella.org/"
    }, */
    {
        id: "xg",
        name: "Xunta de Galicia",
        link: "https://www.xunta.gal/"
    },
    {
        id: "dg",
        name: "Deporte Galego",
        link: "https://deporte.xunta.gal/"
    },
    /* {
        id: "ts",
        name: "Turismo de Santiago",
        link: "https://www.santiagoturismo.com/"
    }, */
    {
        id: "usc",
        name: "Servizo de Participación e Inclusión Universitaria - USC",
        link: "https://www.usc.es/gl/servizos/sepiu/"
    },
    /* {
        id: "lua",
        name: "Lua Gaming",
        link: "https://luagaming.com/"
    }, */
    {
        id: "k",
        name: "Kodular",
        link: "https://www.kodular.io"
    }
];
